export class Colors {
  primary: string = '#00bcf2';
  primary_dark: string = '#00bcf2';
  secondary: string = '#00dca0';
  danger: string = '#eb445a';
  warning: string = '#ffc409';
  light: string = '#f4f5f8';
  gray: string = '#989aa2';
  dark: string = '#001f38';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#F8F8F8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Wennstrøm';
  appID: string = '';
  appHost: string = 'elbillading.wennstrom.com';
  showTitle: boolean = false;
  defaultLang: string = 'nb';
  appToken: string = 'abff5760-26f5-43cd-994c-e2519901da0f';
  pushAppID: string = '84aeafbb-e7a2-4e4a-9874-9b0f54df1e9f';
  pushSenderID: string = '227769150394';
  root: string = 'https://scpay.smartcharge.io';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  stationIcon: StationIcon = { height: 44, width: 30 };
  availableLangs: string[] = ['nb', 'en', 'da', 'sv'];
  toolbarLogoHeight: string = '1.3em';
  privacyPolicy: any = {
    nb: 'https://wennstrom.com/',
    en: 'https://wennstrom.com/'
  };
  termsAndConditions: any = {
    nb: 'https://wennstrom.com/',
    en: 'https://wennstrom.com/'
  };
  contact: any = {
    external: false,
    email: 'kundesenter@wennstrom.com',
    phone: '+47 21404862',
    web: '',
    chat: '',
    customerService: {
      nb: 'https://wennstrom.com/wennstrom-net-as/?lang=no',
      en: 'https://wennstrom.com/wennstrom-net-as/?lang=en'
    }
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
